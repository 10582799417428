declare global {
    interface Window {
        o365_i18n?: {
            [key: string]: {
                [key: string]: string
            }
        },
        o365_framework_i18n?: {
            [key: string]: string
        },
        $t: (pValue: string) => string;
    }
}

const filterCiteriasTemp =  {
    "isnull":"Is null",
    "isnotnull":"Is not null",
    "beginswith": "Starts with",
    "endswith": "Ends with",
    "greaterthan": "Greater than",
    "equals": "Equals",
    "lessthan": "Less than",
    "contains": "Contains",
    "datebetween": "Date between",
    "dateequals": "Date equals",
    "inlist": "In list",
    "datelessthanorequal":"Date less than or equals",
    "dategreaterthanorequal":"Date greater than or equals"
}

const o365_Translations = Object.entries(self?.o365_i18n ?? {}).map(s => s[1]).reduce((acc, cur) => {return {...acc, ...cur}}, {});

const translations = <{[key: string]: string}>{
    ...filterCiteriasTemp,
    ...o365_Translations
}

const translate = (pValue: string, ...args : (string|{})[]): string => {
    let source : string | undefined;
    let argumentsObject : {[key: string]: any} = {};

    for (let arg of args) {
        if (typeof arg == 'string') {
            source = arg;
        }
        if (typeof arg == 'object') {
            argumentsObject = arg;
        }
    }

    let translatedString = newTranslate(pValue, source ?? 'site')
    for (let translateArg in argumentsObject) {
        translatedString = translatedString.replaceAll(`{${translateArg}}`, argumentsObject[translateArg])
    }

    return translatedString;
};

const fallbackTranslate = (pValue: string): string => {
    if (translations.hasOwnProperty(pValue)) {
        return translations[pValue];
    }

    return pValue;
};

const newTranslate = (pValue: string, source: string): string => {
    return self?.o365_i18n?.[source]?.[pValue] ?? fallbackTranslate(pValue);
};

// Make the translate function available in global scope. Required for NT/CT NavBar functionality.
window.$t = translate;

export default translate;